
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
  background-image: linear-gradient(to right, #7f32be, #9f56db, #e00fe0);
}

.app-container {
  margin-top: 200px;
  background-image: linear-gradient(to right, #7f32be, #7729b6, #9632be);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-card {
  text-align: center;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  background: #fafafa;
}

.profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #007bff;
}

h2 {
  color: #333;
}

.user-info {
  margin-top: 10px;
}

.user_data {
  margin: 5px 0;
  color: #666;
}


.buttons {
  margin-top: 20px;
}

.logout-btn, .update-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.logout-btn {
  background-color: #dc3545; 
  color: #fff;
}

.logout-btn:hover {
  background-color: #c82333; 
}

.update-btn {
  background-color: #007bff; 
  color: #fff;
}

.update-btn:hover {
  background-color: #0056b3; 
}


.edit-profile-form {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px;
  background: #fafafa;
}

.edit_home {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.home_input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.home_input:focus {
  border-color: #007bff;
  outline: none;
}
.edit_home{
	border-radius: 15px; 
	padding: 30px; 
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); 
	background-color: #ffffff;  
	max-width: 400px; 
	margin: auto; 
  	color: #999;
	opacity: 1; 
}