/* AdminLogin.css */

.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.admin-login-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.admin-login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.admin-login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.admin-login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.admin-login-form button:hover {
  background-color: #0056b3;
}

.forgot-password {
  display: block;
  text-align: right;
  color: #007bff;
  font-size: 14px;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}
