@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #000000;
  --secondary-color: #f54725;
  --accent-color: #585858; /* Bright green accent */
  --hover-color: #1bbd54; /* Hover state for buttons */
  --text-light: #c4bebe;
  --text-dark: #e5e5e5;
  --max-width: 1200px;
  --header-font: 'Poppins', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--header-font);
}

.landingpage {
  background-color: var(--primary-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: var(--primary-color);
}

.nav__logo a {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-light);
}

.nav__btns {
  display: flex;
  gap: 1rem;
}

.nav__btns .lbtn {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--accent-color);
  color: var(--text-light);
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.nav__btns .lbtn:hover {
  background-color: var(--hover-color);
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header__image img {
  max-width: 70%;
  height: auto;
}

.header__content {
  text-align: center;
}

.header__content h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-dark);
}

.header__content h1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--text-light);
  margin-bottom: 1rem;
}

.lpara {
  font-size: 1.1rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
}

.header__btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.header__btn {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 200;
  color: var(--text-light);
  background-color: var(--accent-color);
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header__btn--outline {
  background-color: transparent;
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
}

.header__btn--outline:hover {
  background-color: var(--accent-color);
  color: var(--text-light);
}

.header__btn:hover {
  background-color: var(--hover-color);
}

@media (min-width: 768px) {
  .header__container {
    flex-direction: row;
  }

  .header__content {
    text-align: left;
  }

  .header__btns {
    justify-content: flex-start;
  }
}
